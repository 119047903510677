<!-- 地址编辑 -->
<template>
  <div class="page-main">
    <header class="page-header">
      <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
      <div class="header-content">地址编辑</div>
    </header>
    <section class="page-info">
      <div class="info-attention">
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
              v-model="formData.organ_name"
              name="organ_name"
              label="医院名称"
              placeholder="医院名称"
              disabled
              :rules="[{ required: true }]"
            />
            <van-field
              v-model="formData.consignee"
              name="consignee"
              label="姓名"
              placeholder="姓名"
              :rules="[{ required: true }]"
            />
            <van-field
              v-model="formData.phone_num"
              name="phone_num"
              label="电话号码"
              placeholder="电话号码"
              maxlength="11"
              :rules="[{ required: true }]"
            />
            <van-field
              v-model="formData.branch_name"
              name="branch_name"
              label="科室信息"
              placeholder="科室信息"
              :rules="[{ required: true }]"
            />
            <van-field
              v-model="formData.room_number"
              name="room_number"
              label="病房号"
              placeholder="病房号"
              :rules="[{ required: true }]"
            />
            <van-field
              v-model="formData.bed_number"
              name="bed_number"
              label="病床号"
              placeholder="病床号"
              :rules="[{ required: true }]"
            />
            <van-field name="default_state" label="默认配送地址">
              <template #input>
                <van-switch v-model="formData.default_state" />
              </template>
            </van-field>
          </van-cell-group>
          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
              保存
            </van-button>
          </div>
          <div style="margin: 16px;" v-if="add_id > 0">
            <van-button round block type="danger" @click="delAddress">
              删除
            </van-button>
          </div>
        </van-form>
      </div>
    </section>

  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import address from "@/api/ofs/address"
// import { getSession } from '@/utils/sessionUtils'
import { getStorage } from '@/utils/localStorageUtils'
import CommonData from '@/utils/commonData'
import { Toast } from 'vant'

export default {
  name: "editAddress",
  components: {},
  setup() {
    const $router = useRouter();
    const { ctx, proxy } = getCurrentInstance();

    onMounted(() => {
      //获取传入参数
      const options = $router.currentRoute.value.query;
      console.log(options)
      if(options != null) {
        if (options.add_id != null) {
          data.add_id = options.add_id
          getAddressInfo()
        }
      }
      if (data.add_id == 0) {
        getOrganInfo()
      }
    });

    const data = reactive({
      add_id: 0,
      organ_id: getStorage(CommonData.KeyStorages.KEY_ORGAN_ID),
      formData: {
        organ_name: "",
        consignee: "",
        phone_num: "",
        branch_name: "",
        room_number: "",
        bed_number: "",
        default_state: true,
        is_default: 1
      }
    });

    const delAddress = () => {
      console.log("delAddress")
      var post_data = {
        is_default: 0,
        add_id: data.add_id,
        state: 3,
        organ_id: data.organ_id
      }
      address.editAddress(post_data).then(res=>{
        $router.go(-1)
      }).catch(err=>{
        Toast(err.message)
      })
    }

    const getAddressInfo = () => {
      const post_data = {
        add_id: data.add_id
      }
      address.getAddressInfo(post_data).then(res=>{
        data.formData = res.data
        data.formData.default_state = res.data.is_default == 1
      })
    }
    const getOrganInfo = () => {
      const post_data = {
        organ_id: data.organ_id
      }
      address.getOrganInfo(post_data).then(res=>{
        data.formData.organ_name = res.data.organ_name
      })
    }

    const onSubmit = (values) => {
      console.log('submit', values);
      var post_data = values
      post_data.is_default = values.default_state?1:0;
      post_data.add_id = data.add_id
      post_data.state = 1
      post_data.organ_id = data.organ_id
      address.editAddress(post_data).then(res=>{
        $router.go(-1)
      }).catch(err=>{
        Toast(err.message)
      })
    };

    return {
      ...toRefs(data),
      delAddress,
      onSubmit
    };
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.page-main {
  width: 100%;
  .page-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    position: relative;
    .btn-left {
      position: fixed;
      left: 16px;
      background-color: #efeff4;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }
    .header-content {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      flex: 1;
      font-weight: 700;
    }
  }
  .page-info {
    background-color: #fff;
    min-height: 300px;
    margin-bottom: 10px;
  }
}
</style>
